import React, { useRef, useEffect } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { useAuth } from 'contexts/auth';
import { useNotif } from 'contexts/notification';
import Loading from 'components/Loading';
import { Modal, ModalOpenButton, ModalBodyResponse } from 'components/Modal';

const AuthenticatedApp = React.lazy(() => import(/* webpackPrefetch: true */ 'AuthenticatedApp'));
const UnauthenticatedApp = React.lazy(() => import('pages/Login'));

function App() {
  const { user } = useAuth();
  const { notif, reset } = useNotif();
  const buttonRef = useRef();

  useEffect(() => {
    if (notif.status !== 'idle') buttonRef.current.click();
  }, [notif]);

  return (
    <React.Suspense fallback={<Loading />}>
      {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      <Modal>
        <ModalOpenButton>
          <button type="button" style={{ display: 'hidden' }} ref={buttonRef}></button>
        </ModalOpenButton>
        <ModalBodyResponse type={notif.status} onExit={reset}>
          {notif.message}
        </ModalBodyResponse>
      </Modal>
      <ReactQueryDevtools initialIsOpen={false} />
    </React.Suspense>
  );
}

export default App;
