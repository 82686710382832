import React, { createContext, useContext, useState, cloneElement } from 'react';
import 'twin.macro';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { SecondaryButton } from 'components/Button';

const callAll =
  (...fns) =>
  (...args) =>
    fns.forEach((fn) => fn && fn(...args));

const ModalContext = createContext();

const Modal = (props) => {
  const [isOpen, setIsOpen] = useState(() => props.open ?? false);

  return <ModalContext.Provider value={[isOpen, setIsOpen]} {...props} />;
};

const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within Modal');
  }
  return context;
};

const ModalOpenButton = ({ children: child }) => {
  const [, setIsOpen] = useModal();
  return cloneElement(child, {
    onClick: callAll(() => setIsOpen(true), child.props.onClick),
  });
};
const ModalCloseButton = ({ children: child }) => {
  const [, setIsOpen] = useModal();
  return cloneElement(child, {
    onClick: callAll(() => setIsOpen(false), child.props.onClick),
  });
};

const ModalBody = ({ title, disableClose = false, children, ...props }) => {
  const [isOpen, setIsOpen] = useModal();
  return (
    <DialogOverlay
      tw="fixed inset-0 bg-black-opacity z-50"
      isOpen={isOpen}
      onDismiss={() => {
        if (!disableClose) setIsOpen(false);
      }}>
      <DialogContent
        aria-label={title ?? 'Modal'}
        tw="my-0 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 p-5 sm:px-6 w-11/12 sm:w-max max-w-md sm:max-w-2xl sm:min-w-md rounded-xl max-h-screen overflow-y-auto"
        {...props}>
        {title && <h2 tw="font-semibold text-lg mb-6 leading-relaxed uppercase">{title}</h2>}
        {children}
      </DialogContent>
    </DialogOverlay>
  );
};

const ModalBodyResponse = ({ type, children, onExit }) => {
  return (
    <ModalBody title={type === 'error' ? 'Perhatian!' : type}>
      <div tw="mx-2 my-8">{children}</div>
      <div tw="flex flex-row justify-end mt-4">
        <ModalCloseButton>
          <SecondaryButton onClick={onExit}>Close</SecondaryButton>
        </ModalCloseButton>
      </div>
    </ModalBody>
  );
};

export { Modal, ModalOpenButton, ModalCloseButton, ModalBody, useModal, ModalBodyResponse };
