import React, { useEffect, useRef } from 'react';
import tw, { css } from 'twin.macro';
import { motion } from 'framer-motion';

const FOCUSABLE_ELEMENTS = [
  'a[href]',
  'area[href]',
  'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
  'select:not([disabled]):not([aria-hidden])',
  'textarea:not([disabled]):not([aria-hidden])',
  'button:not([disabled]):not([aria-hidden])',
  'iframe',
  'object',
  'embed',
  '[contenteditable]',
  '[tabindex]:not([tabindex^="-"])',
];

const ContainerModal = ({
  dismiss,
  header,
  body,
  bgColor = 'white',
  fullScreen = 'false',
  ...props
}) => {
  const elRef = useRef(null);
  if (!elRef.current) {
    elRef.current = document.createElement('div');
  }
  const modalElement = document.getElementsByClassName('backdrop');

  useEffect(() => {
    document.addEventListener('keydown', keydownEventListeners);
    setFocusNode();
    return () => {
      document.removeEventListener('keydown', keydownEventListeners);
      return () => elRef.current.remove();
    };
    // eslint-disable-next-line
  }, []);

  const onBackdropClicked = (e) => {
    if (e.target.classList.contains('backdrop') && dismiss) dismiss();
  };
  const keydownEventListeners = (event) => {
    if (event.keyCode === 9) retainFocus(event);
  };

  const getFocusableNodes = () => {
    const nodes = Array(...modalElement[0].querySelectorAll(FOCUSABLE_ELEMENTS));
    return nodes;
  };

  const setFocusNode = () => {
    const focusableNodes = getFocusableNodes();

    // no focusable nodes
    if (focusableNodes.length === 0) return;
    else focusableNodes[0].focus();
  };

  const retainFocus = (event) => {
    let focusableNodes = getFocusableNodes();

    // no focusable nodes
    if (focusableNodes.length === 0) return;

    /**
     * Filters nodes which are hidden to prevent
     * focus leak outside modal
     */
    focusableNodes = focusableNodes.filter((node) => {
      return node.offsetParent !== null;
    });

    // if disableFocus is true
    if (!modalElement[0].contains(document.activeElement)) {
      focusableNodes[0].focus();
    } else {
      const focusedItemIndex = focusableNodes.indexOf(document.activeElement);

      if (event.shiftKey && focusedItemIndex === 0) {
        focusableNodes[focusableNodes.length - 1].focus();
        event.preventDefault();
      }

      if (
        !event.shiftKey &&
        focusableNodes.length > 0 &&
        focusedItemIndex === focusableNodes.length - 1
      ) {
        focusableNodes[0].focus();
        event.preventDefault();
      }
    }
  };

  return (
    <motion.div
      className="backdrop"
      onClick={onBackdropClicked}
      tw="fixed inset-0 bg-black-opacity z-50"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}>
      <div
        css={[
          tw`absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 py-5 px-5 max-w-full min-w-xs`,
          bgColor === 'white' ? tw`bg-white` : tw``,
          css`
            width: max-content;
          `,
          fullScreen === 'true' ? tw`w-screen h-screen` : tw`rounded-xl`,
        ]}
        {...props}>
        {header && <h1 tw="font-bold text-base m-0 leading-5 uppercase">{header}</h1>}
        {body}
      </div>
    </motion.div>
  );
};

export default ContainerModal;
