import React from 'react';
import tw, { styled, css } from 'twin.macro';
import ModalComponent from 'components/Modal/Container';

const Loading = () => {
  return (
    <ModalComponent
      bgColor="transparent"
      body={
        <LoadingCircle>
          <svg>
            <circle cx="70" cy="70" r="70" />
          </svg>
          <p tw="absolute text-white font-bold text-lg uppercase">Loading</p>
        </LoadingCircle>
      }></ModalComponent>
  );
};

export default Loading;

const LoadingCircle = styled.div(() => [
  tw`relative flex justify-center content-center items-center`,
  css`
    svg {
      position: relative;
      width: 150px;
      height: 150px;
      animation: rotate 2s linear infinite;
    }
    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    svg circle {
      width: 100%;
      height: 100%;
      fill: none;
      stroke-width: 10;
      stroke: var(--color-primary);
      stroke-linecap: round;
      transform: translate(5px, 5px);
      stroke-dasharray: 440;
      stroke-dashoffset: 440;
      animation: animate 4s linear infinite;
    }

    @keyframes animate {
      0%,
      100% {
        stroke-dashoffset: 440;
      }
      50% {
        stroke-dashoffset: 0;
      }
      50.1% {
        stroke-dashoffset: 880;
      }
    }
  `,
]);
