import React, { useState, useCallback } from 'react';

const NotifContext = React.createContext();
NotifContext.displayName = 'NotifContext';

const NotifProvider = ({ children }) => {
  const [notif, setNotif] = useState({
    status: 'idle', // enum: idle, warning, success, error
    message: null,
    resetFn: null,
  });

  const reset = () => setNotif({ status: 'idle', message: null, resetFn: null });

  const addWarningNotif = (message, resetFn) => setNotif({ status: 'reminder', message, resetFn });
  const addSuccessNotif = (message, resetFn) => setNotif({ status: 'success', message, resetFn });
  const addErrorNotif = (message, resetFn) => setNotif({ status: 'error', message, resetFn });

  const contextValue = {
    notif,
    addWarningNotif: useCallback((message, resetFn) => addWarningNotif(message, resetFn), []),
    addSuccessNotif: useCallback((message, resetFn) => addSuccessNotif(message, resetFn), []),
    addErrorNotif: useCallback((message, resetFn) => addErrorNotif(message, resetFn), []),
    reset: useCallback(() => {
      if (typeof notif.resetFn === 'function') notif.resetFn();
      reset();
    }, [notif]),
  };

  return <NotifContext.Provider value={contextValue}>{children}</NotifContext.Provider>;
};

const useNotif = () => {
  const context = React.useContext(NotifContext);
  if (context === undefined) {
    throw new Error(`useNotif must be used inside NotifProvider`);
  }
  return context;
};

export { NotifProvider, useNotif };
