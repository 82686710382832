import axios from 'axios';

const baseURL =
  process.env.REACT_APP_API === 'production'
    ? 'https://admin.pasarnow.com/api/'
    : 'https://admin.pasarnow.com/api/';

const serverAPI = axios.create({
  baseURL,
});

serverAPI.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('USER_KEY');
    if (token) config.headers.Authorization = `Bearer ${token}`;
    config.timeout = 120000;
    if (config.url === 'downloadPODPasarnow') config.timeout = 0;
    return config;
  },
  (error) => Promise.reject(error)
);

const cancelToken = axios.CancelToken;

export { serverAPI, cancelToken };
