import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { AuthProvider } from 'contexts/auth';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      useErrorBoundary: true,
      staleTime: 300000, // 5 minutes
      refetchOnWindowFocus: false,
      // refetchOnMount: true,
      retry(failureCount, error) {
        if (error?.response?.status === 404) return false;
        if (error?.response?.status === 403) return false;
        else if (failureCount < 1) return true;
        else return false;
      },
    },
  },
});

const AppProvider = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>{children}</AuthProvider>
      </Router>
    </QueryClientProvider>
  );
};

export { AppProvider };
