import React from 'react';
import { QueryCache, useQueryClient, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import decode from 'jwt-decode';
import { serverAPI } from 'utils/API';

const AuthContext = React.createContext();
AuthContext.displayName = 'AuthContext';
const queryCache = new QueryCache();
const ADMIN_ACCESS = {
  transaction_access: 'transactions',
  warehouse_access: 'warehouse',
  b2c_label_access: 'promo-b2c',
  b2b_label_access: 'promo-b2b',
  pricing_scheme_access: 'pricing-scheme',
  category_access: 'category',
  user_access: 'user',
  cashout_access: 'cashout',
  mitra_access: 'mitra',
  b2b_access: 'b2b',
  hub_access: 'hub',
  sales_access: 'sales',
  events_access: 'events',
  voucher_access: 'vouchers',
  gamification_access: 'gamification',
  recipe_access: 'recipes',
  faq_access: 'faq',
  banner_access: 'banner',
  pasarnow_access: 'pasarnow',
  horeca_access: 'horeca',
  horeca_address_list_access: 'horeca-address',
  horeca_billing_info_access: 'horeca-billing',
  horeca_order_page_access: 'horeca-order',
  horeca_sales_access: 'horeca-sales',
  horeca_transaction_access: 'horeca-transactions',
  horeca_warehouse_access: 'horeca-warehouse',
  horeca_fleet_access: 'horeca-fleet',
  horeca_catalog_access: 'horeca-catalog',
  horeca_group_access: 'horeca-group',
  partnership_access: 'partnership',
  partnership_order_page_access: 'partnership-order',
  partnership_address_access: 'partnership-address',
  partnership_billing_info_access: 'partnership-billing',
  partnership_sales_access: 'partnership-sales',
  partnership_transaction_access: 'partnership-transactions',
  partnership_group_access: 'partnership-group',
};

const getAdminAccess = (admin = {}) => {
  const keys = Object.keys(admin);
  const accessList = keys.reduce((prev, current) => {
    let access = ADMIN_ACCESS[current];
    if (admin.super_user) prev.push('admin');
    if (admin[current].has_access === true && access !== undefined) prev.push(access);
    if (current === 'transaction_access' && admin[current].view_b2b_transactions === true)
      prev.push('transactions-b2b');
    if (current === 'transaction_access' && admin[current].transaction_history === true) {
      prev.push('transaction_history');
    }
    if (current === 'transaction_access' && admin[current].view_b2c_transactions === true)
      prev.push('transactions-b2c');
    if (current === 'warehouse_access' && admin[current].view_product_list === true)
      prev.push('product');
    if (current === 'warehouse_access' && admin[current].vendor_list === true) prev.push('vendor');
    if (current === 'sales_access' && admin[current].view_b2b === true) prev.push('sales-b2b');
    if (current === 'sales_access' && admin[current].view_b2c === true) prev.push('sales-b2c');
    if (
      current === 'recipe_access' &&
      (admin[current].create_banner === true || admin[current].update_banner === true)
    )
      prev.push('recipe-banner');
    return prev;
  }, []);

  return accessList;
};

const getAdminInfo = () => {
  let admin = null;
  const token = localStorage.getItem('USER_KEY');
  if (token) {
    const user = decode(token);

    const access = getAdminAccess(user);
    admin = {
      userLevel: user.userLevel,
      username: user.username,
      location: user.location,
      access,
      transaction_access: user.transaction_access,
      warehouse_access: user.warehouse_access,
      b2c_label_access: user.b2c_label_access,
      b2b_label_access: user.b2b_label_access,
      pricing_scheme_access: user.pricing_scheme_access,
      category_access: user.category_access,
      user_access: user.user_access,
      cashout_access: user.cashout_access,
      mitra_access: user.mitra_access,
      b2b_access: user.b2b_access,
      hub_access: user.hub_access,
      sales_access: user.sales_access,
      events_access: user.events_access,
      voucher_access: user.voucher_access,
      gamification_access: user.gamification_access,
      recipe_access: user.recipe_access,
      faq_access: user.faq_access,
      banner_access: user.banner_access,
      pasarnow_access: user.pasarnow_access,
      horeca_access: user.horeca_access,
      horeca_address_list_access: user.horeca_address_list_access,
      horeca_billing_info_access: user.horeca_billing_info_access,
      horeca_order_page_access: user.horeca_order_page_access,
      horeca_sales_access: user.horeca_sales_access,
      horeca_transaction_access: user.horeca_transaction_access,
      horeca_warehouse_access: user.horeca_warehouse_access,
      fleet_access: user.fleet_access,
      horeca_catalog_access: user.horeca_catalog_access,
      horeca_group_access: user.horeca_group_access,
      partnership_order_page_access: user.partnership_order_page_access,
      partnership_address_access: user.partnership_address_access,
      partnership_billing_info_access: user.partnership_billing_info_access,
      partnership_sales_access: user.partnership_sales_access,
      partnership_transaction_access: user.partnership_transaction_access,
      partnership_group_access: user.partnership_group_access,
      super_user: user.super_user ?? false,
      is_kino_admin: user.is_kino_admin ?? false,
    };
    Sentry.setUser({ username: user.username, token });
    // console.log('admin', admin, user);
  }
  return admin;
};

const AuthProvider = (props) => {
  const history = useHistory();
  const [user, setUser] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [menu, setMenu] = React.useState('');
  const queryClient = useQueryClient();
  const { mutate } = useMutation((formData) => serverAPI.post('/adminLogin', formData), {
    onSuccess: ({ data }, variables, context) => {
      localStorage.setItem('USER_KEY', data.token);
      const admin = getAdminInfo();
      setUser(admin);
      setError(null);
    },
    onError: (error, variables, context) => {
      setError(error);
    },
  });

  const resetToken = React.useCallback((token) => {
    localStorage.setItem('USER_KEY', token);
    const admin = getAdminInfo();
    setUser(admin);
    setError(null);
  }, []);

  const login = React.useCallback((form) => mutate(form), [mutate]);

  const canIAccess = React.useCallback(
    (menu) => {
      if (user?.super_user) return true;
      const access = user.access.find((access) => access === menu);
      if (access !== undefined) return true;

      return false;
    },
    [user]
  );

  const canIDoAction = React.useCallback(
    (action, menu2) => {
      if (user?.super_user) return true;
      return user?.[menu2 ?? menu]?.[action] === true;
    },
    [user, menu]
  );

  const logout = React.useCallback(() => {
    queryCache.clear();
    localStorage.removeItem('USER_KEY');
    setUser(null);
    history.replace('/');
    Sentry.configureScope((scope) => scope.setUser(null));
  }, [history]);

  React.useLayoutEffect(() => {
    const admin = getAdminInfo();
    if (!admin || admin.location?.length === 0) {
      logout();
    } else {
      setUser({ ...admin });
      setError(null);
      queryClient.setQueryData('admin', admin);
    }
  }, [queryClient, logout]);

  const resetError = React.useCallback(() => setError(null), []);

  serverAPI.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response && error?.response?.status === 401) {
        logout();
        window.location.assign(window.location);
        // return Promise.reject({ message: 'Please login again.' });
      }
      return Promise.reject(error);
    }
  );

  const value = React.useMemo(
    () => ({
      user,
      login,
      logout,
      error,
      resetError,
      canIAccess,
      canIDoAction,
      menu,
      setMenu,
      resetToken,
      locations: !user
        ? []
        : user.location.length > 1
        ? [
            { display: 'All Locations', value: '' },
            ...user.location.map((loc) => ({ display: loc, value: loc })),
          ]
        : user.location.map((loc) => ({ display: loc, value: loc })),
    }),
    [login, logout, user, error, resetError, canIAccess, canIDoAction, menu, setMenu, resetToken]
  );

  return <AuthContext.Provider value={value} {...props} />;
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used inside AuthProvider`);
  }
  return context;
};

export { AuthProvider, useAuth };
