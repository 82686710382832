import React from 'react';
import tw, { css, styled } from 'twin.macro';

const StyledButton = styled.button(({ icon, flat, disabled, outlineRed, color, size }) => [
  tw`w-full h-full py-2 rounded-md max-h-10 font-semibold uppercase text-sm px-2 leading-relaxed flex justify-center items-center`,
  icon && tw`px-2 flex justify-center items-center rounded-full text-lg`,
  flat && tw`shadow-none hover:shadow-none`,
  disabled && tw`cursor-not-allowed shadow-none hover:shadow-none`,

  size === 'big' && tw`text-base`,
  size === 'small' && tw`py-1 text-xs`,
  outlineRed && tw`border border-red text-red`,
  color === 'white' && tw`border border-black-70`,
  color === 'yellow' && tw`bg-yellow border border-yellow`,
  color === 'green' && tw`text-white bg-green`,
  color === 'red' && tw`bg-red border border-red text-white`,
]);

const Button = ({ type = 'button', ...props }) => <StyledButton type={type} {...props} />;

const disabledBtn = css`
  opacity: 0.5;
  cursor: not-allowed;
  -webkit-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
`;
const loadingBtn = css`
  opacity: 0.5;
  cursor: wait;
  -webkit-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  transition: all 0.3s linear;
`;

const outlinedBtn = css`
  background-color: white;
  border-width: 1px;
  border-color: ${({ color = 'black-80' }) => `var(--color-${color})`};
`;
const containedBtn = css`
  color: ${({ color }) => (color === 'primary' ? `var(--color-black-100)` : `var(--color-white)`)};
  border-width: 1px;
  border-color: ${({ color }) => `var(--color-${color})` || `none`};
  background-color: ${({ color }) => `var(--color-${color})` || `none`};
`;
const colorBtn = css`
  color: ${({ color }) => `var(--color-${color})` || `var(--color-${color})`};
  border-color: ${({ color }) => `var(--color-${color})` || `transparent`};
`;

const NewButton = styled.button`
  ${tw`rounded-md font-semibold flex items-center justify-center`}
  padding: 0.5em 1.5em;
  line-height: 1.5;
  ${(props) => props.color && colorBtn}
  ${(props) => props.variant === 'outlined' && outlinedBtn}
  ${(props) => props.variant === 'contained' && containedBtn}
  ${(props) => props.disabled && disabledBtn}
  ${(props) => props.isLoading && loadingBtn}
`;

export const PrimaryButton = React.forwardRef(
  ({ type = 'button', color = 'primary', ...props }, ref) => (
    <NewButton
      ref={ref}
      type={type}
      color={color}
      variant="contained"
      disabled={props.isLoading ? true : props.disabled || false}
      {...props}
    />
  )
);

export const SecondaryButton = React.forwardRef(
  ({ type = 'button', color = 'black-80', ...props }, ref) => (
    <NewButton
      ref={ref}
      type={type}
      color={color}
      variant="outlined"
      disabled={props.isLoading ? true : props.disabled || false}
      {...props}
    />
  )
);

export const TextButton = React.forwardRef(
  ({ type = 'button', color = 'black-80', ...props }, ref) => (
    <NewButton
      ref={ref}
      type={type}
      color={color}
      disabled={props.isLoading ? true : props.disabled || false}
      {...props}
    />
  )
);

export default Button;
