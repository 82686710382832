import './wdyr.js';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from 'App';
import { AppProvider } from 'contexts';
import { NotifProvider } from 'contexts/notification';
import reportWebVitals from 'reportWebVitals';
import GlobalStyles from 'assets/styles/GlobalStyles';
import 'react-datepicker/dist/react-datepicker.css';
import 'tailwindcss/dist/base.css';
import 'assets/styles/global.scss';
import '@reach/dialog/styles.css';

Sentry.init({
  dsn: 'https://bf694520b3754d80b7848a1ebee3fa2e@o547083.ingest.sentry.io/5669277',
  release: process.env.REACT_APP_API + `@${process.env.REACT_APP_VERSION}`,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.2,
  maxBreadcrumbs: 10,
  beforeSend(event) {
    if (event.user) delete event.user.email;
    return event;
  },
  beforeBreadcrumb(breadcrumb, hint) {
    const { category } = breadcrumb;
    if (category === 'ui.click' || category === 'navigation') return null;
    return breadcrumb;
  },
});

ReactDOM.render(
  <React.StrictMode>
    <GlobalStyles />
    <AppProvider>
      <NotifProvider>
        <App />
      </NotifProvider>
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
