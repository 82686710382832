import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { theme, GlobalStyles as BaseStyles } from 'twin.macro';

const CustomStyles = createGlobalStyle`
:root {
  --color-white: ${theme`colors.white`};
  --color-black-10: ${theme`colors.black.10`};
  --color-black-20: ${theme`colors.black.20`};
  --color-black-60: ${theme`colors.black.60`};
  --color-black-70: ${theme`colors.black.70`};
  --color-black-80: ${theme`colors.black.80`};
  --color-black-100: ${theme`colors.black.100`};
  --color-primary: ${theme`colors.primary`};
  --color-yellow: ${theme`colors.yellow`};
  --color-green: ${theme`colors.green`};
  --color-ivory: ${theme`colors.ivory`};
  --color-blue: ${theme`colors.blue`};
  --color-red: ${theme`colors.red`};
  --color-orange: ${theme`colors.orange`};
  --color-cyan: ${theme`colors.cyan`};
  --color-purple: ${theme`colors.purple`};
  --color-lime: ${theme`colors.lime`};
}

html {
  font-size: 14px;
  color: ${theme`colors.black.100`};
  font-weight: 500;
  max-height: 100vh;
  overflow: hidden;
  font-family: ${theme`fontFamily.worksans`}
}

body {
  line-height: initial;
}

button {
  line-height: initial;
}

*, *::before, *::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input, select {
  font-weight: 500;
  line-height: normal;
}
input:focus {
  outline: 0;
}
input[type='text']:read-only {
  opacity: 0.5;
}
input::focus-visible {
  outline: none;
  box-shadow: 0 0 0 1px ${theme`colors.black.80`};
  transition: box-shadow .3s ease;
}
input[type="time"]::-webkit-calendar-picker-indicator { 
  background: none; 
  ${'' /* display:none;  */}
}
.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}


button {
  line-height: initial;
}

/* FORMS */
 select:disabled {
  color: ${theme`colors.black.70`};
}
input {
  max-height: 32px;
}
input[type='text']:disabled {
  color: ${theme`colors.black.70`};
}
${
  '' /* input:read-only {
  color: ${theme`colors.black.70`};
} */
}
::placeholder {
  color: ${theme`colors.black.70`};
  opacity: 1;
}
:-ms-input-placeholder,
::-ms-input-placeholder {
  color: ${theme`colors.black.70`};
}
/* FORMS */

.disabled\:bg-white:disabled {
  background-color: white;
}

.disabled\:text-gary-500:disabled {
  color: ${theme`colors.black.70`};
}

.disabled\:border-black-60:disabled {
  border-color: ${theme`colors.black.60`};
}

@keyframes ripple {
  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }
  50% {
    box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0);
  }
}

.md-radio input[type='radio'] {
  display: none;
}
.md-radio input[type='radio']:checked + label:before {
  border-color: ${theme`colors.green`};
  animation: ripple 0.2s linear forwards;
}
.md-radio input[type='radio']:disabled + label:before {
  border-color: ${theme`colors.black.70`};
  animation: ripple 0.2s linear forwards;
}
.md-radio input[type='radio']:checked + label:after {
  transform: scale(1);
}
.md-radio input[type='radio']:disabled + label:after {
  background-color: ${theme`colors.black.70`};
}
.md-radio label {
  display: inline-block;
  min-height: 20px;
  line-height: 1.25rem;
  position: relative;
  padding: 0 0 0 1.4rem;
  margin-bottom: 0;
  cursor: pointer;
  vertical-align: bottom;
}
.md-radio label:before,
.md-radio label:after {
  position: absolute;
  content: '';
  border-radius: 50%;
  transition: all 0.3s ease;
  transition-property: transform, border-color;
}
.md-radio label:before {
  left: 0;
  top: 0;
  width: 16px;
  height: 16px;
  border: 1px solid  ${theme`colors.green`};
}
.md-radio label:after {
  top: 3px;
  left: 3px;
  width: 10px;
  height: 10px;
  transform: scale(0);
  background:  ${theme`colors.green`};
}
@media (min-width: 640px) {
  .md-radio label {
    padding: 0 0 0 1.7rem;
  }
}
.react-datepicker {
  display: flex;
}
.react-datepicker-wrapper {
  display: block !important;
}
.ql-container {
  min-height: 7rem;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.ql-toolbar {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.popper-datepicker {
  z-index: 20;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

[data-reach-dialog-overlay],
[data-reach-dialog-content] {
  animation: slide-down 0.5s ease;
}

.current-location__btn {
  background-color: white;
  border: none;
  outline: none;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  cursor: pointer;
  margin-right: 10px;
  padding: 0;
}

.current-location__icon {
  margin: auto;
  width: 18px;
  height: 18px;
  background-image: url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png);
  background-size: 180px 18px;
  background-position: 0px 0px;
  background-repeat: no-repeat;
}

.tooltip {
  opacity: 1;
  padding: .5rem 1rem !important;
  border-radius: .25rem !important;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
}

/* remove input number errorws */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

`;

const GlobalStyles = () => (
  <>
    <BaseStyles />
    <CustomStyles />
  </>
);

export default GlobalStyles;
